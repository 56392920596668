<template>
    <div>
        <div style='position: fixed; width: 1000px; background: white; z-index: 2;'>
            <center> <v-pagination
                     class='py-5'
            v-model="page"
            :length="pages"
            :total-visible="7"
            ></v-pagination>
            </center>
            <hr style="width:975px;"/>
        </div>
        <div style='padding-top:90px'>
        <div v-for="(lesson,index) in progress" :key="lesson.id" >
            <div  v-if="lesson && lesson.topic  && lesson.topic.section">
                <p class="line" 
                   v-if="(index==0 && lesson.topic  && lesson.topic.section) || 
                   (lesson.topic  && lesson.topic.section && 
                   progress[index-1].topic && progress[index-1].topic.section &&
                   lesson.topic.section.name!= progress[index-1].topic.section.name)"><span>{{ lesson.topic.section.name}}</span></p>
                <v-list-item >
                    <v-list-item-action>
                        <v-icon v-if="lesson.progress" color="green">mdi-check-circle-outline</v-icon>
                        <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title style='white-space: unset;'>
                            <v-row><v-col >{{lesson.topic.name}} {{lesson.name}}<br>
                                    <v-chip small  v-if="lesson.type=='theory'">Теоретическое занятие</v-chip>
                                    <v-chip small  color="primary"  v-if="lesson.type=='practice'">Практическое занятие</v-chip>
                                    <v-chip small color="warning"  v-if="lesson.type=='self'">Самостоятельная подготовка</v-chip>
                                    <v-chip  small  color="error"  v-if="lesson.type=='control'">Контроль</v-chip>
                                    <v-chip  small class='mx-3' outlined>Часов: {{lesson.hours}}</v-chip>
                                </v-col>
                                <v-col cols="4" v-if="lesson.progress">  
                                    <b>Дата начала:</b> {{ parseInt(lesson.progress.startTime) | moment("D MMMM YYYY  HH:mm") }}<br>
                                    <b>Дата завершения:</b> <span v-if="lesson.progress.endTime">{{ parseInt(lesson.progress.endTime) | moment("D MMMM YYYY  HH:mm") }}</span><span v-else>-</span><br>
                                    <b>Времени затрачено:</b> {{Math.ceil(lesson.progress.time/60)}} мин.
                                </v-col>
                            </v-row></v-list-item-title>


                        <div v-if="lesson.blocks.length>0">
                            <div v-for="test in lesson.blocks" :key="test.id" class="ma-1">
                                <v-card>
                                    <v-card-text><v-icon color="red" v-if='test.attemps.length==0'>mdi-alert</v-icon><b>Контроль:</b> {{test.name}}<br>
                                        
                                            <v-simple-table v-if='test.attemps.length>0'>
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                Попытка
                                                            </th>
                                                            <th class="text-left">
                                                                Количество вопросов
                                                            </th>
                                                            <th class="text-left">
                                                                Правильных
                                                            </th>
                                                            <th class="text-left">
                                                                Время начала
                                                            </th>
                                                            <th class="text-left">
                                                                Время завершения
                                                            </th>
                                                            <th class="text-left">
                                                                Оценка
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            v-for="(attemp,aindex) in test.attemps" 
                                                            :key="attemp.id"
                                                            >
                                                            <td>{{aindex+1}}</td>
                                                            <td>{{attemp.countQuestions}}</td>
                                                            <td>{{attemp.countCorrect}}</td>
                                                            <td>{{ parseInt(attemp.startTime) | moment("D MMMM YYYY  HH:mm") }}</td>
                                                            <td>{{ parseInt(attemp.endTime) | moment("D MMMM YYYY  HH:mm") }}</td>
                                                            <td><v-chip  small class='mx-1' outlined :color="5*attemp.countCorrect/attemp.countQuestions>3?'green':'red'">{{ 5*attemp.countCorrect/attemp.countQuestions }}</v-chip></td>
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>

                                        </v-card-text>
                                </v-card>
                            </div>
                        </div>
                    </v-list-item-content>


                </v-list-item>
                <v-divider/>
            </div>

        </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "index",
        data: () => ({
                page: 1,
                pages: 1,
                progress: [],
                headers: [
                    {text: 'Раздел', value: 'section'},
                    {text: 'Тема', value: 'topic'},

                    {text: 'Занятие', value: 'name'},
                    {text: 'Дата начала', value: 'protein'},
                    {text: 'Дата последнего обращения', value: 'iron'},
                    {text: 'Проведено времени', value: 'iron'},
                    {text: '', value: 'data-table-expand'},
                ],
            }),
        props: {
            groupCourseId: Number,
            userId: Number
        },
        watch: {
            page() {
                this.fetchData()
            },
            userId() {
                this.fetchData()
            },
            groupCourseId() {
                this.fetchData()
            }
        },
        methods: {
            fetchData() {
                if (this.userId && this.groupCourseId) {
                    this.$http.get('courses/progress?groupCourseId=' + this.groupCourseId + '&userId=' + this.userId + '&page=' + this.page).then(response => {
                        this.progress = response.data.lessons
                        this.pages = response.data.pages
                    })
                }
            }
        },
        mounted() {
            this.fetchData()
        },
        components: {

        }
    };
</script>